import { largeTabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import catBg from '@propertypal/shared/src/resources/bg/enzo-bg.jpg';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  background-image: url(${catBg.src});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;

  h1,
  h2,
  p {
    color: ${(props) => props.theme.white};
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
  }

  h2 {
    font-size: 30px;
    margin: 50px 0 15px;
    font-weight: 800;
  }

  p {
    font-size: 20px;
  }

  @media (${largeTabletMax}) {
    justify-content: flex-start;
    height: calc(100vh - 130px);

    h1 {
      margin-top: 30px;
      font-size: 36px;
    }

    h2 {
      font-size: 22px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const FooterContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 900px;
  padding: 0 15px;

  p {
    color: ${(props) => props.theme.white};
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    font-size: 16px;
    margin-bottom: 30px;
  }
`;
