import PageNotFound from '@propertypal/web-ui/src/jumbotrons/PageNotFound';
import Head from 'next/head';
import React, { FunctionComponent } from 'react';

const Custom404: FunctionComponent = () => {
  return (
    <>
      <Head>
        <title>Page Not Found</title>
      </Head>

      <PageNotFound />
    </>
  );
};

export default Custom404;
