import platform from '@propertypal/shared/src/utils/platform';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import Chevron from '../icons/Chevron';
import { LinkText } from '../typography';
import { Container, FooterContent } from './PageNotFound.style';

const PageNotFound: FunctionComponent = () => {
  return (
    <Container>
      <h1>We&apos;ve looked everywhere...</h1>

      <p>
        <em>(even under the sofa)</em>
      </p>

      <h2>But we still can&apos;t find what you&apos;re looking for.</h2>

      <p>
        Want to start again?{' '}
        <Link href="/">
          <LinkText as="span">
            Go to our homepage <Chevron size={12} />
          </LinkText>
        </Link>
      </p>

      <FooterContent>
        <p>
          If you reached this page and something is clearly wrong, please let us know with a quick email to{' '}
          <LinkText href={`mailto:${platform.helpEmail}`}>{platform.helpEmail}</LinkText>. Be sure to mention (if
          possible) what caused you to land here in the first place. We&apos;ll owe you one!
        </p>
      </FooterContent>
    </Container>
  );
};

export default PageNotFound;
